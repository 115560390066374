import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ElementWrapper from "../ElementWrapper";
import Select from 'react-select'

export const FormSelectComponent = ({
  id,
  name,
  options,
  value,
  values,
  errors,
  isError,
  handleChange,
  ...otherProps
}) => {
  const [curOption, setCurOption] = useState(null);

  useEffect(() => {
    const item = options.filter(
      (option) =>
        option.value === value || (values && option.value === values[id])
    );
    if (item.length > 0) {
      setCurOption({
        ...item[0],
        ...(!item[0].label && { label: item[0].value }),
      });
    } else {
      setCurOption(null);
    }
  }, [value, values?.[id]]);

  const onHandleChange = (value) => {
    if (handleChange !== undefined) {
      handleChange(id, value.value);
    }
  };

  return (
    <FormSelectWrapper className="form-element--wrapper form-select--wrapper">
       <Select
        classNamePrefix='form-select'
        {
          ...(curOption && { value: curOption })
        }
        onChange={onHandleChange}
        options={options.map((option) => ({
          label: option.label || option.value,
          value: option.value,
        }))}
        isSearchable={false}
        {...otherProps}
      />
    </FormSelectWrapper>
  )
}

export const FormSelect = ElementWrapper(FormSelectComponent);

const FormSelectWrapper = styled.div`
  font-family: 'Roboto';

  .form-select__control {
    border-radius: 30px;
    height: 44px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  .form-select__value-container {
    padding: 2px 14px;
  }

  .form-select__placeholder {
    color: #8F9BB3;
  }

  .form-select__single-value,
  .form-select__input {
    color: #101828;
  }

  .form-select__indicators {
    padding-right: 7px;
    
    .form-select__indicator-separator {
      display: none;
    }
  }
`