import React from "react"
import styled from "styled-components"
import ElementWrapper from "../ElementWrapper";

export const FormInputComponent = ({
  id,
  name,
  placeholder,
  value,
  type,
  values,
  errors,
  isError,
  handleFocus,
  handleBlur,
  handleChange,
  handleKeyPress,
  ...otherProps 
}) => {
  const onHandleFocus = (event) => {
    if (handleFocus !== undefined) handleFocus(id);
  };

  const onHandleBlur = (event) => {
    if (handleBlur !== undefined) handleBlur(id, event.target.value);
  };

  const onHandleChange = (event) => {
    if (handleChange !== undefined) handleChange(id, event.target.value);
  };

  const onHandleKeyPress = (event) => {
    if (handleKeyPress !== undefined) handleKeyPress(event);
  };

  return (
    <FormInputWrapper className="form-element--wrapper form-input--wrapper">
      <input
        className='form-input--input'
        id={id}
        name={name || id}
        type={type || 'text'}
        placeholder={placeholder}
        onBlur={onHandleBlur}
        onChange={onHandleChange}
        onFocus={onHandleFocus}
        onKeyPress={onHandleKeyPress}
        {
          ...((value || values) && { value: value || (values && values[id]) || '' })
        }
        {...otherProps}
      />
    </FormInputWrapper>
  )
}

export const FormInput = ElementWrapper(FormInputComponent);

const FormInputWrapper = styled.div`
  font-family: 'Roboto';

  .form-input--input {
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    padding: 10px 14px;

    &::placeholder {
      color: #8F9BB3;
    }
  }
`