import React from "react"
import styled from "styled-components"
import { IconButton } from '@atoms';
import { FormInput } from "@components/Form/Elements";
import FormWrapper from "../FormWrapper";

const SimpleDemo1FormComponent = ({
  title = 'Let’s Get Started',
  subtitle,
}) => {
  return (
    <SimpleDemo1FormWrapper className="form--wrapper simple-demo-form--wrapper">
      <div className="form--heading">
        <h2>{title}</h2>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <div className="form--body">
        <FormInput
          label="First Name"
          placeholder="Greg"
        />
        <FormInput
          label="Last Name"
          placeholder="Worthington"
        />
        <FormInput
          label="Business Email"
          placeholder="gworthington@business.com"
        />
      </div>
      <div className="form--footer">
        <div className="form--footer-right">
          <IconButton className="form--submit-btn" ctaVariant='primary' ctaLink='/'>Request a Demo</IconButton>
        </div>
      </div>
    </SimpleDemo1FormWrapper>
  )
}

export const SimpleDemo1Form = FormWrapper(SimpleDemo1FormComponent);

const SimpleDemo1FormWrapper = styled.div`
  font-family: 'Roboto';
  padding: 64px;

  &.form--wrapper {
    gap: 24px 0;
  }

  .form--heading {
    h2, p {
      text-align: center;
    }
  }
  
  .form--body {
    gap: 16px 30px;
  }

  .form--footer {
    display: flex;
    flex-direction: column;
    gap: 16px 30px;

    .form--footer-right {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    padding: 48px 32px;
  }

  @media (max-width: 576px) {
    .form--body {
      flex-direction: column;

      .form-element--container {
        width: 100%;
      }
    }

    .form--footer {
      flex-direction: column;
    }
  }
`