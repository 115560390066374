import React from 'react';
import styled from 'styled-components';
import Dialog from "rc-dialog";
import 'rc-dialog/assets/index.css';
import './dialog.css';

function FormWrapper (WrapperForm) {
  const WrappedForm = ({ isModal, ...props }) => {
    const formContent = () => (
      <FormContainer className="form--container" transparent={props.transparent} darkBack={props.darkBack}>
        <WrapperForm {...props} />
      </FormContainer>
    );

    if (isModal) {
      return (
        <Dialog
          className="form--dialog"
          visible={props.modalOpen}
          onClose={props.handleModalClose}
          zIndex={100000}
          animation="zoom"
          maskAnimation="fade"
          style={{ marginTop: 150 }}
          closeIcon={<img src="/icons/close-black.svg" alt="close modal"/>}
        >
          {formContent()}
        </Dialog>
      )
    }

    return formContent();
  };

  return WrappedForm;
};

export default FormWrapper;

const FormContainer = styled.div`
  font-family: Roboto;
  background: ${props => props.transparent ? 'transparent' : '#FFFFFF'};
  border: ${props => props.transparent ? 'none' : '1px solid #E4E7EC'};
  box-shadow: ${props => props.transparent ? 'none' : '0px 20px 30px rgba(16, 24, 40, 0.05)'};
  border-radius: 6px;
  width: 100%;

  .form--wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px 0;
  }

  .form--heading {
    display: flex;
    flex-direction: column;
    gap: 16px 0;

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      color: ${props => props.darkBack ? 'white' : '#252D38'};
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.darkBack ? '#D0D5DD' : '#475467'};
    }
  }

  .form--body {
    display: flex;
    flex-direction: column;
    gap: 16px 30px;

    .form-element--label {
      color: ${props => props.darkBack ? '#919FAF' : '#344054'};
    }
  }

  .form--footer {
    .form--footer-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #98A2B3;
      margin: 0;

      a {
        color: #002DC2;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 992px) {
    .form--heading {
      h2 {
        font-size: 28px;
        line-height: 34px;
      }
    }
  }
`;