import React from "react"
import styled from "styled-components"

export const PopupSubscribeForm = ({
  headingKicker,
  title,
  subtitle,
  modalOpen,
  handleModalClose,
  handleModalSubmit,
}) => {
  return (
    <PopupSubscribeFormWrapper className={`form--wrapper popup-subscribe-form--wrapper ${modalOpen ? 'form--modal-open' : ''}`}>
      <div className="popup-form--overlay" />
      <div className="popup-form--close" onClick={() => handleModalClose()}><img src="/icons/close-white.svg" alt="close modal" /></div>
      <div className="popup-form--content">
        <div className="popup-form--back" />
        <div className="popup-form--main-content">
          <div className="popup-form--heading-content">
            <span>{headingKicker}</span>
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </div>
          <div className="popup-form--email-content">
            <input placeholder="Enter email address" />
            <button>Subscribe</button>
          </div>
        </div>
      </div>
    </PopupSubscribeFormWrapper>
  )
}

const PopupSubscribeFormWrapper = styled.div`
  &.form--wrapper.popup-subscribe-form--wrapper {
    visibility: hidden;
    font-family: 'Roboto';
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;

    .popup-form--close {
      position: absolute;
      right: 30px;
      top: 30px;
      width: 50px;
      height: 50px;
      padding: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1000005;

      img {
        width: 16px;
        height: 16px;
      }
    }
  
    .popup-form--overlay {
      width: 100%;
      height: 100%;
      background: #00000033;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(37, 45, 56, 0.8);
      backdrop-filter: blur(20px);
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }

    .popup-form--content {
      width: 90%;
      max-width: 1300px;
      height: 100%;
      left: -100%;
      top: 0;
      transition: all 0.4s ease-in-out;
      position: absolute;
    }
  
    .popup-form--back {
      width: 100%;
      height: 100%;
      background-color: white;
      background-image: url('/images/popup-form-back.png');
      background-size: cover;
      background-position: center;
      -webkit-mask-image: url('/images/popup-form-mask.png');
      -webkit-mask-size: auto;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: top right;
    }

    .popup-form--main-content {
      display: flex;
      align-items: flex-start;
      top: 280px;
      right: 0;
      position: absolute;
    }

    .popup-form--heading-content {
      display: flex;
      flex-direction: column;
      gap: 24px 0;
      width: 50%;
      max-width: 610px;
      padding-left: 40px;

      span {
        font-weight: 900;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #002DC2;
        margin: 0;
      }

      h2 {
        font-weight: 900;
        font-size: 68px;
        line-height: 72px;
        color: #101828;
        margin: 0;
      }

      p {
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        color: #475467;
        margin: 0;
      }
    }

    .popup-form--email-content {
      width: 570px;
      height: 74px;
      background: #002DC2;
      box-shadow: 0px 12px 20px 5px rgba(27, 27, 27, 0.15);
      border-radius: 60px;
      z-index: 1000003;
      display: flex;
      align-items: center;
      padding: 8px 14px;
      margin-top: 110px;

      input {
        flex: auto;
        background: #FFFFFF;
        border-radius: 40px 0px 0px 40px;
        height: 52px;
        outline: none;
        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 14px 27px;

        &::placeholder {
          color: #7A8A9D;
        }
      }

      button {
        width: 121px;
        height: 58px;
        background: none;
        color: white;
        outline: none;
        border: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        cursor: pointer;
      }
    }

    &.form--modal-open {
      visibility: visible;

      .popup-form--overlay {
        opacity: 1;
      }

      .popup-form--content {
        left: 0;
      }
    }

    @media (max-width: 992px) {
      .popup-form--content {
        width: 100%;
      }
      .popup-form--back {
        -webkit-mask-image: url('/images/popup-form-mask-md.png');
        -webkit-mask-size: 100% 100%;
      }
      .popup-form--main-content {
        flex-direction: column;
        width: 70%;
        max-width: 690px;
        left: 0;
        top: 40%;
        transform: translateY(-50%);
        padding: 0 60px;

        .popup-form--heading-content {
          padding: 0;
          width: 100%;
    
          h2 {
            font-size: 50px;
            line-height: 60px;
          }
        }

        .popup-form--email-content {
          margin-top: 30px;
          width: 100%;
        }
      }
    }

    @media (max-width: 768px) {
      .popup-form--close {
        top: 3px;
        right: 17px;
      }

      .popup-form--back {
        -webkit-mask-image: url('/images/popup-form-mask-sm.png');
      }

      .popup-form--main-content {
        width: 100%;
        top: 150px;
        transform: none;
        padding: 0 35px;
      }

      .popup-form--heading-content {
        max-width: 80%;
      }
    }
  
    @media (max-width: 576px) {
    }
  }
`