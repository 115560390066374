import React from "react"
import { Link } from "gatsby"
import { Layout } from "@components/Layout"
import { FormTestSection } from "@components/Form"

export default function FormTestPage() {
  return (
    <Layout>
      <FormTestSection />
    </Layout>
  )
}
