import React from "react"
import styled from "styled-components"
import FormWrapper from "../FormWrapper";
import { FormInput, FormPhoneInput, FormSelect, FormFooterCheck } from "@components/Form/Elements";
import { Button, IconButton } from '@atoms';
import { countryList } from "@utils/text";

const DemoFormComponent = ({
  title = 'Schedule Your Demo',
  subtitle,
  showCompany,
  showPhone = true,
  showCountry = true,
  fullWidth,
  formType = 'normal',   // normal, hero
  transparent,
}) => {
  return (
    <DemoFormWrapper className={`form--wrapper demo-form--wrapper ${fullWidth ? 'full-width-form--wrapper' : 'half-width-form--wrapper'} ${transparent ? 'transparent-form--wrapper' : ''}`}>
      <div className="form--heading">
        <h2>{title}</h2>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <div className="form--body">
        <FormInput
          label="First Name"
          placeholder="Greg"
        />
        <FormInput
          label="Last Name"
          placeholder="Worthington"
        />
        <FormInput
          label="Business Email"
          placeholder="gworthington@business.com"
        />
        {showCompany && (
          <FormInput
            label="Company"
            placeholder="gworthington@business.com"
          />
        )}
        {showPhone && (
          <FormPhoneInput
            label="Phone number"
            placeholder="+1 (555) 000-0000"
          />
        )}
        {showCountry && (
          <FormSelect
            id="country"
            label="Country"
            placeholder="Country"
            options={countryList.map(country => ({ value: country }))}
          />
        )}
      </div>
      <div className={`form--footer form--footer-${formType}`}>
        <div className="form--footer-left">
          <FormFooterCheck
            label={`Yes, I subscribe to marketing communications from Workgrid about it’s products, events, and content.`}
          />
          <p className="form--footer-description">By filling out and submitting the form you understand and agree that Workgrid processes your personal information in accordance with the <a href="/privacy-policy">Workgrid Privacy Policy.</a></p>
        </div>
        <div className="form--footer-right">
          {formType === 'normal' ? (
            <Button className="form--submit-btn" ctaVariant='primary'>Submit</Button>
          ) : (
            <IconButton className="form--submit-btn" ctaVariant='primary' ctaLink='/'>Request a Demo</IconButton>
          )}
        </div>
      </div>
    </DemoFormWrapper>
  )
}

export const DemoForm = FormWrapper(DemoFormComponent);

const DemoFormWrapper = styled.div`
  font-family: 'Roboto';
  padding: 64px 48px;

  .form--footer {
    display: flex;
    align-items: center;
    gap: 16px;

    .form--footer-left {
      display: flex;
      flex-direction: column;
      gap: 8px 0;
    }

    &.form--footer-normal {
      .form--footer-right {
        button {
          max-width: 114px;
          min-width: 100px !important;
        }
      }
    }

    &.form--footer-hero {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &.full-width-form--wrapper {
    .form--body {
      flex-direction: row;
      flex-wrap: wrap;

      .form-element--container {
        width: calc(50% - 15px);
      }
    }
  }

  @media (max-width: 992px) {
    padding: 32px;

    &.half-width-form--wrapper {
      .form--footer.form--footer-normal {
        flex-direction: column-reverse;
  
        .form--footer-right {
          width: 100%;
  
          button {
            width: 100%;
            max-width: 100% !important;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    &.full-width-form--wrapper {
      .form--body {
        display: flex;
        flex-direction: column;

        .form-element--container {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 576px) {
    &.full-width-form--wrapper .form--footer,
    .form--footer-hero.form--footer {
      flex-direction: column;

      .form--footer-right {
        width: 100%;

        button {
          width: 100%;
          max-width: 100% !important;
        }
      }
    }
    
  }
`