import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button } from '@atoms';
import FormModal from "../../Modal/FormModal";

export const FormTestSection = () => {
  const [open, setOpen] = useState(false)
  const [formId, setFormId] = useState()

  const closeModal = () => {
    setOpen(false)
  }

  const openModal = (id) => {
    setFormId(id)
    setOpen(true)
  }

  return (
    <FormTestSectionContainer>
      <div>
        <div className="form-test--section-content" style={{ background: 'linear-gradient(180deg, #090069 23.61%, rgba(9, 0, 105, 0.2) 98.49%)' }}>
          <div className="form-test--section form-test--popup-subscribe-section">
            <div>
              <h1>ROI calculator Form</h1><br/>
              <p>Opening this modal will display the ROI Calculator form.</p><br/>
              <div onClick={() => openModal(1814)}><Button ctaVariant='primary'>Get Started</Button></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="form-test--section-content" style={{ background: 'linear-gradient(180deg, #090069 23.61%, rgba(9, 0, 105, 0.2) 98.49%)' }}>
          <div className="form-test--section form-test--popup-subscribe-section">
            <div>
              <h1>Hero Form</h1><br/>
              <p>Opening this modal will display the hero form as an example.</p><br/>
              <div onClick={() => openModal(1633)}><Button ctaVariant='primary'>Get Started</Button></div>
            </div>
          </div>
        </div>
      </div>
      {typeof window !== "undefined" && (
        <FormModal 
          show={open} 
          onClose={closeModal} 
          formId={formId}
        />
      )}
    </FormTestSectionContainer>
  )
}

const FormTestSectionContainer = styled.div`
  margin-top: 100px;
  font-family: Roboto;

  .form-test--section {
    padding: 96px 48px;
    max-width: 1170px;
    margin: 0 auto;
  }

  .form-test--demo-section {
    display: flex;
    align-items: center;
    gap: 30px;

    & > * {
      flex: 1;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .form-test--simple-demo-section {
    display: flex;
    align-items: stretch;

    & > div {
      &:first-child {
        flex: 2;
        height: auto;
        background: #F2F4F7;
        border-radius: 6px 0px 0px 6px;
        padding: 68px 24px;

        div {
          background: #00000088;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 32px;
          color: white;
        }
      }
      &:last-child {
        flex: 1;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column-reverse;

      & > div {
        &:first-child {
          flex: auto;
          height: 460px;
          padding: 32px;
        }
      }
    }
  }

  .form-test--hero-demo-section {
    display: flex;
    align-items: center;
    gap: 30px;

    & > div {
      flex: 1;

      &:first-child {
        color: white;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .form-test--popup-demo-section {
    display: flex;
    align-items: center;
    gap: 30px;

    & > div {
      flex: 1;
      text-align: center;
      max-width: 770px;
      margin: 0 auto;

      &:first-child {
        color: white;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .form-test--popup-subscribe-section {
    gap: 30px;

    & > div {
      &:first-child {
        text-align: center;
        max-width: 770px;
        margin: 0 auto;
        color: white;
      }
    }

  }

`