import React from 'react';
import styled from 'styled-components';

function ElementWrapper (WrapperElement) {
  const WrappedElement = ({ label, ...otherProps }) => {
    return (
      <FormElementContainer className='form-element--container'>
        {label && <label className='form-element--label'>{label}</label>}
        <WrapperElement {...otherProps} />
      </FormElementContainer>
    )
  };

  return WrappedElement;
};

export default ElementWrapper;

const FormElementContainer = styled.div`
  width: 100%;

  .form-element--label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 0 0 6px;
  }

  .form-element--wrapper {
    width: 100%;
  }
`;