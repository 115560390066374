import styled, { css } from 'styled-components';

export const FormModalWrap = styled.div`
  display: inline-block;
  font-family:"Roboto";
  z-index: 9999999;
  position: absolute;
`;

export const Modal = styled.div`
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: rgba(37, 45, 56, 0.8);
  backdrop-filter: blur(10px);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
  ${props => props?.visible && css`
    pointer-events: auto;
    opacity: 1;
    z-index: 100000;
    transition: all 300ms ease-in-out;
    height: 100vh;
  `}
`

export const ModalWrap = styled.div`
  position: relative;
  display: block;
  min-height: 400px;
  width: 1170px;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-color: transparent;
  align-self: center;
  opacity: 0;
  max-width: 90%;
  transform: scale(0.6);
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  transform: scale(0);
  @media (max-width: 992px) {
    width: 100%;
    min-width: auto;
    padding: 10px;
  }
  ${props => props?.visible && css`
    max-width: 770px;
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
    @media (max-width: 768px) {
      max-width: 506px;
    }
  `}
`

export const CloseWrap = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 32px;
  top: 32px;
  z-index: 2;
  svg {
    path {
      stroke: #101828;
    }
  }
`
