import React from "react"
import styled from "styled-components"
import ElementWrapper from "../ElementWrapper";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const FormPhoneInputComponent = ({
  id,
  name,
  placeholder,
  value,
  type,
  values,
  errors,
  isError,
  handleFocus,
  handleBlur,
  handleChange,
  handleKeyPress,
  ...otherProps 
}) => {
  const onHandleFocus = (event) => {
    if (handleFocus !== undefined) handleFocus(id);
  };

  const onHandleBlur = (event) => {
    if (handleBlur !== undefined) handleBlur(id, event.target.value);
  };

  const onHandleChange = (phone, country, e, formattedValue) => {
    console.log({ phone, country, e, formattedValue })
    if (handleChange !== undefined) handleChange(id, phone);
  };

  const onHandleKeyPress = (event) => {
    if (handleKeyPress !== undefined) handleKeyPress(event);
  };

  return (
    <FormPhoneInputWrapper className="form-element--wrapper form-phone-input--wrapper">
      <PhoneInput
        name={name}
        country="us"
        placeholder={placeholder}
        onBlur={onHandleBlur}
        onChange={onHandleChange}
        onFocus={onHandleFocus}
        onKeyPress={onHandleKeyPress}
        {
          ...((value || values) && { value: value || (values && values[id]) || '' })
        }
      />
    </FormPhoneInputWrapper>
  )
}

export const FormPhoneInput = ElementWrapper(FormPhoneInputComponent);

const FormPhoneInputWrapper = styled.div`
  font-family: 'Roboto';

  .react-tel-input {
    .form-control {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #D0D5DD;
      box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
      border-radius: 30px;
      color: #101828;
      padding: 10px 14px 10px 58px;
      
      &::placeholder {
        color: #8F9BB3;
      }
    }

    .flag-dropdown {
      background: transparent !important;
      border: none;
      border-radius: 0;
      left: 10px;

      .selected-flag {
        background: transparent !important;
      }
    }

  }
`