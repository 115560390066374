import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import { CloseWrap, FormModalWrap, Modal, ModalWrap } from './styles/FormModal.styled'
import ReactDOM from "react-dom"
import { createWrapperAndAppendToBody } from '../../utils/createWrapperAndAppendToBody'
import MktoForm from '../Form/MktoForm'
import FocusTrap from 'focus-trap-react'
import onKeyDown from '../../utils/onKeyDown'
import { CloseButton } from '../../utils/IconsList'

const FormModal = ({ show, reference, onClose, wrapperId = "react-portal-wrapper", formId, }) => {
  const [domReady, setDomReady] = useState(false)
  const [wrapperElement, setWrapperElement] = useState(null);
  const [visible, setVisible] = useState(false)
  const modalRef = useRef(null);
  
  useEffect(() => {
    show ? setVisible(true) : setVisible(false)
  }, [show]);

  useEffect(()=> {
    setDomReady(true)
  }, [])

  // This useLayoutEffect was informed by https://blog.logrocket.com/build-modal-with-react-portals/ 
  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;

    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    return () => {
      if (systemCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    }
  }, [wrapperId]);

  return domReady && ReactDOM.createPortal(
    <FocusTrap active={show}>
      <FormModalWrap id={wrapperId}>
        <Modal ref={modalRef} visible={visible}>
          <ModalWrap visible={visible}>
            {show && 
              <CloseWrap
                onClick={onClose}
                role="button"
                onKeyDown={(e) => onKeyDown(e, onClose)}
                aria-label="close modal"
                className="close_btn"
                alt={reference?.title || 'close icon'}
                tabIndex={0}
              >
                <CloseButton/>
              </CloseWrap>
            }
            {show && 
              <MktoForm 
                formId={formId} 
                background={"white"} 
                onClose={onClose} 
                component={{type: "modal"}} 
              />
            }
          </ModalWrap>
        </Modal>
      </FormModalWrap>
    </FocusTrap>,
    wrapperElement
  );
};

export default React.memo(FormModal);
