import React from "react"
import styled from "styled-components"
import FormWrapper from "../FormWrapper";
import { FormInput, FormFooterCheck } from "@components/Form/Elements";
import { IconButton } from '@atoms';

const SimpleDemoFormComponent = () => {
  return (
    <SimpleDemoFormWrapper className="form--wrapper simple-demo-form--wrapper">
      <div className="form--heading">
        <h2>Request a Demo</h2>
      </div>
      <div className="form--body">
        <FormInput
          placeholder="First Name"
        />
        <FormInput
          placeholder="Last Name"
        />
        <FormInput
          placeholder="Company Name"
        />
        <FormInput
          placeholder="Email"
        />
      </div>
      <div className="form--footer">
        <div className="form--footer-left">
          <FormFooterCheck
            label={`Yes, I subscribe to marketing communications from Workgrid about it’s products, events, and content.`}
          />
          <p className="form--footer-description">By filling out and submitting the form you understand and agree that Workgrid processes your personal information in accordance with the <a href="/privacy-policy">Workgrid Privacy Policy.</a></p>
        </div>
        <div className="form--footer-right">
          <IconButton className="form--submit-btn" ctaVariant='primary' ctaLink='/'>Request a Demo</IconButton>
        </div>
      </div>
    </SimpleDemoFormWrapper>
  )
}

export const SimpleDemoForm = FormWrapper(SimpleDemoFormComponent);

const SimpleDemoFormWrapper = styled.div`
  font-family: 'Roboto';
  padding: 32px 46px;

  &.form--wrapper {
    gap: 24px 0;
  }
  
  .form--body {
    gap: 16px 30px;
  }

  .form--footer {
    display: flex;
    flex-direction: column;
    gap: 16px 30px;

    .form--footer-left {
      display: flex;
      flex-direction: column;
      gap: 8px 0;
    }

    .form--footer-right {
      button {
        width: 100%;
        max-width: 100% !important;
      }
    }
  }

  @media (max-width: 992px) {
    padding: 32px;

    .form--body {
      flex-direction: row;
      flex-wrap: wrap;

      .form-element--container {
        width: calc(50% - 15px);
      }
    }

    .form--footer {
      flex-direction: row;

      & > div {
        flex: 1;
      }
    }
  }

  @media (max-width: 576px) {
    .form--body {
      flex-direction: column;

      .form-element--container {
        width: 100%;
      }
    }

    .form--footer {
      flex-direction: column;
    }
  }
`