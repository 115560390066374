import React from "react"
import styled from "styled-components"

export const FormFooterCheck = ({
  id,
  label,
  value,
  handleChange,
}) => {
  const onHandleChange = (event) => {
    if (handleChange !== undefined) handleChange(id, event.target.value);
  };

  return (
    <FormFooterCheckWrapper className="form-element--wrapper form-footer-check--wrapper">
      <label>
        <input
          className="form-footer-check--input"
          type="checkbox"
          onChange={onHandleChange}
          {
            ...(value && { value })
          }
        />
        {label}
      </label>
    </FormFooterCheckWrapper>
  )
}

const FormFooterCheckWrapper = styled.div`
  font-family: 'Roboto';

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #98A2B3;
    display: flex;
    align-items: flex-start;
  }

  .form-footer-check--input {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    background-color: #EEEEEE;
    border: 1px solid #D3D3D3;
    border-radius: 4px;
    position: relative;
    margin-right: 16px;

    &:active, &:checked:active {
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }

    &:checked {
      background-color: #e9ecee;
      border: 1px solid #adb8c0;
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);

      &:after {
        content: ' ';
        position: absolute;
        width: 10px;
        height: 10px;
        top: 3px;
        left: 3px;
        background: #002DC2;
        border-radius: 2px;
      }
    }
  }
`